import { FieldValueResponse } from '../models/Field'
import { GroupResponse } from '../models/Group'
import { OrgNodeResponse } from '../models/OrganizationalNode'
import { AddAccessRequest, RemoveAccessRequest, Roles } from '../models/Roles'
import {
  Application,
  ApproveModalResponse,
  ApproveUserRequest,
  InviteModalResponse,
  InviteUserRequest,
  OrganizationResponse,
  OverviewUserResponse,
  StatusResponse,
} from '../models/User'
import http from './http-common'

class UserService {
  async listOrganizations(page = 0, size = 50): Promise<OrganizationResponse> {
    return (await http.get('/my-organizations', { params: { page, size } }))?.data
  }

  async listApplications(organization: string): Promise<Application[]> {
    return (await http.get(`/${organization}/applications/access`))?.data
  }

  async listGroups(organization: string, page = 0, size = 20, search?: string): Promise<GroupResponse> {
    return (
      await http.get(`/${organization}/group`, {
        params: {
          page,
          size,
          search,
        },
      })
    )?.data
  }

  async listOrgNodes(organization: string, page = 0, size = 20, search = ''): Promise<OrgNodeResponse> {
    return (
      await http.get(`/${organization}/organization-nodes`, {
        params: {
          page,
          size,
          search: search && search !== '' ? search : undefined,
        },
      })
    )?.data
  }

  async listStatuses(organization: string, page = 0, size = 20, search?: string): Promise<StatusResponse> {
    return (
      await http.get(`/${organization}/user/statuses`, {
        params: {
          page,
          size,
          search,
        },
      })
    )?.data
  }

  async listOrganizationUsers(
    organization: string,
    page = 0,
    size = 10,
    groupIds?: string[],
    organizationNodeIds?: string[],
    applicationCodes?: string[],
    statusIds?: string[],
    name?: string,
    email?: string,
  ): Promise<OverviewUserResponse> {
    return (
      await http.get(`/${organization}/users-overview`, {
        params: {
          page,
          size,
          groupIds,
          organizationNodeIds,
          applicationCodes,
          statusIds,
          name,
          email,
        },
        paramsSerializer: { indexes: null },
      })
    )?.data
  }

  async listFieldValues(
    organization: string,
    field: string,
    page = 0,
    size = 20,
    search?: string,
    groupIds?: string[],
    organizationNodeIds?: string[],
    applicationCodes?: string[],
    statusIds?: string[],
    name?: string,
    email?: string,
  ): Promise<FieldValueResponse> {
    return (
      await http.get(`/${organization}/user/field-filters/${field}`, {
        params: {
          page,
          size,
          search,
          groupIds,
          organizationNodeIds,
          applicationCodes,
          statusIds,
          name,
          email,
        },
        paramsSerializer: { indexes: null },
      })
    )?.data
  }

  async fetchInviteModal(organization: string): Promise<InviteModalResponse> {
    return (await http.get(`/${organization}/user/invite`)).data
  }

  async fetchApproveUserModal(organization: string): Promise<ApproveModalResponse> {
    return (await http.get(`/${organization}/user/modal`)).data
  }

  async inviteUser(organization: string, body: InviteUserRequest) {
    return (await http.post(`/${organization}/user/invite`, body)).data
  }

  async removeUser(organization: string, id: string) {
    return (await http.post(`/${organization}/user/${id}/remove`)).data
  }

  async activateUser(organization: string, id: string) {
    return (await http.post(`/${organization}/user/${id}/activate`)).data
  }

  async rejectUser(organization: string, id: string) {
    return (await http.post(`/${organization}/user/${id}/reject`)).data
  }

  async deactivateUser(organization: string, id: string) {
    return (await http.post(`/${organization}/user/${id}/deactivate`)).data
  }

  async resendInvite(organization: string, id: string) {
    return (await http.post(`/${organization}/user/${id}/reinvite`)).data
  }

  async approveUser(organization: string, id: string, body: ApproveUserRequest) {
    return (await http.post(`/${organization}/user/${id}/approve`, body)).data
  }

  async getRolesForApp(app: string): Promise<Roles> {
    return (await http.get(`/${app}/roles`)).data
  }

  async addAccessToApp(organization: string, id: string, body: AddAccessRequest) {
    return (await http.post(`/${organization}/user/${id}/allow-access`, body)).data
  }

  async removeAccessFromApp(organization: string, id: string, body: RemoveAccessRequest) {
    return (await http.post(`/${organization}/user/${id}/deny-access`, body)).data
  }
}

export default new UserService()
